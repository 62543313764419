body {
    margin: 0;
    padding: 0;
    font-family: proxima-nova, 'proxima nova', 'helvetica neue', helvetica, arial, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.clientCardTitle {
    cursor: pointer;
}

.locationCard {
    margin-bottom: 4px;
}

.action-item-row:nth-of-type(odd) {
    background-color: #f2f2f2;
}

.rounded-container-no-border-warning {
    border-radius: 0.35rem;
    padding: 5px;
    background: #ffc107;
}

.rounded-container-no-border-success {
    border-radius: 0.35rem;
    padding: 5px;
    background: #28a745;
}

.rounded-container {
    margin: 5px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.35rem;
    display: block;
}

.rounded-container-info {
    border-left: 4px solid #0069ff !important;
}

@keyframes borderblink {
    0% {
        border-left: 4px solid rgba(255, 0, 0, 1);
    }
    50% {
        border-left: 4px solid rgba(255, 0, 0, 0.25);
    }
    100% {
        border-left: 4px solid rgba(255, 0, 0, 1);
    }
}

.rounded-container-warning {
    border-left: 4px solid red !important;
    animation-name: borderblink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.login-container {
    background-color: white;
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

@keyframes fade-in-slow {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.login-container-show {
    animation-name: fade-in-slow;
    animation-duration: 1s;
}

.location-item:hover {
    background-color: cornflowerblue;
}

.fadein-appear {
    opacity: 0.01;
}
.fadein-appear.fadein-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.locationedit-container {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

button {
    margin: 5px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    /*float:right;*/
}

/* Hide default HTML checkbox */
.switch input {
    display: none;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input.default:checked + .slider {
    background-color: #444;
}
input.primary:checked + .slider {
    background-color: #2196f3;
}
input.success:checked + .slider {
    background-color: #8bc34a;
}
input.info:checked + .slider {
    background-color: #3de0f5;
}
input.warning:checked + .slider {
    background-color: #ffc107;
}
input.danger:checked + .slider {
    background-color: #f44336;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.no-padding {
    padding: 0 !important;
}

.checkbox-1x {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
}

.highlight {
    text-shadow: 1px 1px #ff0000;
    -webkit-box-shadow: -5px 0px 2px 0px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: -5px 0px 2px 0px #ccc; /* Firefox 3.5 - 3.6 */
    box-shadow: -5px 0px 2px 0px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

a:hover {
    cursor: pointer;
    text-decoration: underline !important;
}

.fas-link-add:hover {
    color: blue;
    cursor: pointer;
    text-decoration: none !important;
}

.fas-link-trash:hover {
    color: red;
    cursor: pointer;
    text-decoration: none !important;
}

.treenode {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px;
    position: relative;
}

.treenode-on-drag-over-drop-allowed {
    -webkit-box-shadow: 5px 5px 5px 0px rgba(144, 238, 144, 1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(144, 238, 144, 1);
    box-shadow: 5px 5px 5px 0px rgba(144, 238, 144, 1);
}

.treenode-on-drag-over-drop-blocked {
    -webkit-box-shadow: 2px 2px 2px 0px rgba(255, 0, 0, 1);
    -moz-box-shadow: 2px 2px 2px 0px rgba(255, 0, 0, 1);
    box-shadow: 2px 2px 2px 0px rgba(255, 0, 0, 1);
}

.treenode:hover {
    background: lightgray;
}

.treenode-row:hover {
    background: lightgray;
}

.drag-blocked-icon {
    position: absolute;
    top: 5px;
    left: 5px;
}

.orderHistoryRow:hover {
    cursor: pointer;
    text-decoration: underline;
}

.top-right-attention {
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 999;
}
